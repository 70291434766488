import React from 'react';
import Button from 'react-bootstrap/Button'
import './style.css'

interface IProps {

}

interface IState {
    carModel: string,
    year: string,
    quality: string,
    budget: string,
    location: string,
    notes: string,
    name: string,
    phoneNumber: string
}

class RegistrationForm extends React.Component<IProps, IState> {
    state: IState;
    constructor(props: IProps) {
        super(props);
        this.state = {
            carModel: '',
            year: '',
            quality: '',
            budget: '',
            location: '',
            notes: '',
            name: '',
            phoneNumber: ''
        };
    }

    hanldeInputChange = (event: any) => {
        let updatedState = {};
        const name = event.target.name;
        const value = event.target.value;
        if (name === "name") {
            updatedState = {
                name: value,
            }
        } else if (name === "phoneNumber") {
            updatedState = {
                phoneNumber: value
            }
        } else if (name === "model") {
            updatedState = {
                carModel: value
            }
        } else if (name === "notes") {
            updatedState = {
                notes: value
            }
        } else if (name === "quality") {
            updatedState = {
                quality: value
            }
        }
        else if (name === "budget") {
            updatedState = {
                budget: value
            }
        }
        else if (name === "year") {
            updatedState = {
                year: value
            }
        }
        else if (name === "location") {
            updatedState = {
                location: value
            }
        }
        else if (name === "quality") {
            updatedState = {
                location: value
            }
        } else {

        }
        this.setState(updatedState);
    }

    disableSubmitButton = (): boolean => !(
        this.state.carModel !== '' &&
        this.state.year !== '' &&
        this.state.notes !== '' &&
        this.state.quality !== '' &&
        this.state.budget !== '' &&
        this.state.location !== '' &&
        this.state.name !== '' &&
        this.state.phoneNumber !== ''
    );

    notesPlaceholder = "مثال: أبحث عن بنفر خلفي وكالة. أو أبحث عن اكسل. رجاء اكتب اكبر قدر من التفاصيل لكي نجد القطعة المناسبة.";

    onButtonSubmit = (e: any) => {
        e.preventDefault();
        console.log(this.state);
        const message = `طلب جديد من موقع كاراجي.
        
نوع السيارة: ${this.state.carModel}
السنة: ${this.state.year}
تفاصيل الطلب: ${this.state.notes}
الجودة: ${this.state.quality}
الميزانية: ${this.state.budget}
الموقع: ${this.state.location}
الإسم: ${this.state.name}

بإمكانك إرسال صور إضافية مع هذه الرسالة.
        
سنبحث لك عن القطعة التي تريدها من المناطق الصناعية وسنتواصل معك خلال ٢٤ ساعة.`;

        const encodedMessage = encodeURIComponent(message);

        window.open("https://wa.me/96898912060?text=" + encodedMessage);

    }

    render() {
        return (
            <div className="formCard">
                <span>اكتب بياناتك هنا</span>
                <form>
                    <InputField label="نوع السيارة" placeholder="مثال: تويوتا كامري ٦ سلندر" name="model" type="text" value={this.state.carModel} onChange={this.hanldeInputChange} />
                    <InputField label="سنة الصنع" placeholder="مثال: ٢٠١٩" name="year" type="text" value={this.state.year} onChange={this.hanldeInputChange} />
                    <InputLabel label="تفاصيل الطلب" />
                    <textarea className="no-margin" rows={3} placeholder={this.notesPlaceholder} name="notes" value={this.state.notes} onChange={this.hanldeInputChange}></textarea>
                    <InputField label="جودة القطعة" placeholder="مثال: وكالة، سكراب او نقلي" name="quality" type="text" value={this.state.quality} onChange={this.hanldeInputChange} />
                    <InputField label="ميزانيتك" placeholder="مثال: ٢٥ ريال" name="budget" type="text" value={this.state.budget} onChange={this.hanldeInputChange} />
                    <InputField label="موقعك" placeholder="مثال: الخوير" name="location" type="text" value={this.state.location} onChange={this.hanldeInputChange} />
                    <InputField label="إسمك" placeholder="الإسم" name="name" type="text" value={this.state.name} onChange={this.hanldeInputChange} />
                    <InputField label="الهاتف" placeholder="رقم الواتساب" name="phoneNumber" type="text" value={this.state.phoneNumber} onChange={this.hanldeInputChange} />
                    <br />
                    <Button disabled={this.disableSubmitButton()} onClick={this.onButtonSubmit}> ارسال الطلب (ستذهب الى رقمنا على الواتساب) </Button>
                </form>
            </div>
        );
    }
}

type InputFieldType = {
    placeholder: string,
    label: string,
    name: string,
    type: string,
    value: any,
    onChange: (e: any) => void
}

function InputField({ placeholder, label, name, type, value = undefined, onChange }: InputFieldType) {
    return (
        <div>
            <InputLabel label={label} />
            <input className="margin-bot"
                placeholder={placeholder}
                name={name}
                type={type}
                value={value}
                onChange={onChange} />
        </div>
    );
}

// type SelectFormType = {
//     label: string,
//     placeholder: string,
//     value: any,
//     options: object[]
//     onChange: (e: any) => void
// }

// function SelectForm({ label, placeholder, options, value, onChange }: SelectFormType) {
//     return (<div>
//         {/* <div style={{textAlign: "start"}}><span>{label}</span></div>  */}
//         <InputLabel label={label} />
//         <Select
//             className="margin-bot circular-border"
//             placeholder={placeholder}
//             value={value}
//             options={options}
//             onChange={onChange}
//         />
//     </div>);
// }

type InputLabelType = {
    label: string
}

function InputLabel({ label }: InputLabelType) {
    return (<div style={{ textAlign: "start" }}><span>{label}</span></div>);
}

export default RegistrationForm;

