import React from 'react';
import './App.css';
import RegistrationForm from '../RequestForm';
import NavBar from '../NavBar';
import ServicesSummary from '../ServicesSummary';

interface IProps {
}

interface IState {
  health: number
}

class App extends React.Component<IProps, IState> {
  state: IState;
  constructor(props: IProps) {
    super(props);
    this.state = {
      health: 2,
    }
  }

  search = (n: string): string => {
    return "" + n.toLowerCase();
  }

  render() {
    return (
      <div dir="rtl" className="App">
        <header className="App-header">
          <NavBar></NavBar>
          <ServicesSummary></ServicesSummary>
          <RegistrationForm></RegistrationForm>
        </header>
      </div>
    );
  }
}

export default App;
