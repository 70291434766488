import React from 'react';
import './style.css';

class ServicesSummary extends React.Component {
    render() {
        return (
            <div className="summary">
                <span>تدور عن قطعة لسيارتك من الصناعية؟ لا تتعب نفسك، خبرنا ويش تريد واحنا نجيب لك القطعة الي تريدها بالضبط خلال ٢٤ ساعة.</span>
            </div>
        );
    }
}

export default ServicesSummary;