import React from 'react';
import './style.css';

class NavBar extends React.Component {
    render() {
        return (
            <div className="nav-bar">
                {/* <br></br><br></br> */}
                <div className="logo">
                    <h1>Caragy</h1>
                </div>
            </div>
        );
    }
}

export default NavBar;